:root {
    --primary-color: 0, 50, 250;
    --primary-darker-color: 0, 60, 211;
    --primary-lighter-color: 0, 51, 178;
}

body {
    overflow-x: unset;
}

table.htCore td {
    padding: 4px;
    overflow: visible !important;
}

table.htCore td > div {
    box-sizing: border-box;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: left;
}

/* NACRE TOP BAR OVRRIDE */
.wz-topmenu .wz-universe {
    flex: none;
}

.wz-topmenu .wz-universe .wz-logo {
    max-width: 65px;
    background-size: cover;
    height: 95%;
    margin-top: 2%;
    margin-left: 3px;
}

.wz-topmenu .wz-organization {
    margin-left: 10px;
}

.wz-topmenu .wz-module {
    flex: auto;
}

.wz-topmenu .wz-module .wz-module-information {
    margin-left: auto;
}

.container-dropdown-account {
    display: flex;
    align-items: center;
}

.icon-help {
    background: url('images/icon.svg');
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
    color: white;
}

/*Tooltip Help*/
.wz-tooltip__bottom {
    left: 30%;
    top: 40px;
}

a.link-button-update-browser:hover {
    color: white;
}

div.socium-drawer {
    height: calc(100% - 50px);
    top: auto;
    bottom: 0;
}

@keyframes sidebar-slide {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

/* We have to hav another animation because navigator can't re-trigger the same*/
@keyframes sidebar-slide-out {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}

@media only screen and (max-width: 480px) {
    .wz-header__on-top {
        margin-left: 0px;
    }
}
