.invitations-list :global(.wz-header__on-top) {
	margin-left: 0!important;
}

.invitation-creation-container {
	margin: 0 20px 0 20px;
}

.invitations_container {
	padding: 0 20px;
}

.stats-card-container {
	margin: 0 20px 0 20px;
	display: flex;
}

.stats-card-container :global(.wz-statistics-group) {
	flex-grow: 1;
}

.add-guests-cta {
	display: block !important;
}

.add-guests-cta + :global.wz-block-dropdownheader {
	display: none !important;
}

@media screen and (min-width: 1260px) {
	.add-guest-dropdown:global(.wz-button-dropdown) {
		display: none;
	}
}

@media screen and (max-width: 1260px) {

    /* Unset the display of stats card container to let nacre make the responsive
    on mobile after deleting add attendee button */
	.stats-card-container {
		display: block;
	}
}
