.wz-topmenu {
    background: rgb(var(--primary-color)) !important;
}

.wz-ui-input:not(.wz-ui-input__errors) .wz-input-text:focus, .wz-ui-input:not(.wz-ui-input__errors) .wz-input-checkbox:focus, .wz-ui-input:not(.wz-ui-input__errors) .wz-input-radio:focus, .wz-ui-input:not(.wz-ui-input__errors) .wz-input-color:focus, .wz-ui-input:not(.wz-ui-input__errors) .wz-input-number:focus, .wz-ui-input:not(.wz-ui-input__errors) .wz-input-password:focus {
    box-shadow: 0 2px 5px -2px rgb(var(--primary-color)) !important;
    border-color: rgb(var(--primary-color)) !important;
}

.wz-ui-input:not(.wz-ui-input__disabled):not(.wz-ui-input__errors).wz-ui-input-icon:hover .wz-input {
    border-color: rgb(var(--primary-color)) !important;
}

.wz-input.wz-input-checkbox:checked, .wz-input.wz-input-radio:checked {
    border-color: rgb(var(--primary-color)) !important;
}

.wz-input.wz-input-checkbox:checked {
    background-color: rgb(var(--primary-color)) !important;

}

.wz-button__inverted:hover {
    color: rgb(var(--primary-color)) !important;
    border-color: rgb(var(--primary-color)) !important;
}


.wz-framebutton.wz-framebutton__disabled.wz-button__ticket.wz-button__inverted {
    border-color: rgba(rgb(var(--primary-color)), .3) !important;
}

/* .wz-button__ticket, .wz-button-primary.wz-framebutton:not(.wz-button__inverted) {
    background-color: rgb(var(--primary-color)) !important;
} */

.wz-framebutton.wz-button.wz-button__inverted.wz-button-primary.wz-ctatopheader {
    background-color: #fff !important;
}

.wz-button__ticket.wz-button__inverted {
    border-color: rgb(var(--primary-color)) !important;
    color: rgb(var(--primary-color)) !important;
}

.wz-button__ticket.wz-button__inverted:hover {
    color: rgb(var(--primary-darker-color)) !important;
    border-color: rgb(var(--primary-darker-color)) !important;
}

.wz-button__inverted, .wz-account, .wz-numbercard {
    border-color: rgb(var(--primary-color)) !important;
    color: rgb(var(--primary-color)) !important;
}

.wz-label.wz-label__link.wz-label__inverted:hover, .wz-label.wz-label__link.wz-label__inverted:hover.wz-label-icon i {
    color: rgb(var(--primary-color)) !important;
    border-color: rgb(var(--primary-color)) !important;
}

.wz-label.wz-label__link.wz-label__inverted:hover, .wz-commondropdown-list .wz-dropdowncommonitem:not(.wz-dropdowncommonitem__disabled):hover, .wz-commondropdown-list .wz-dropdowncommonitem.wz-dropdowncommonitem__active {
    background-color: #FFF !important;
}

.wz-commondropdown-list .wz-item:hover {
    color: rgb(var(--primary-color)) !important;
}

.wz-input.wz-input-text:hover, .wz-input.wz-input-checkbox:hover, .wz-input.wz-input-radio:hover, .wz-input.wz-input-color:hover, .wz-input.wz-input-textarea:hover, .wz-input.wz-input-number:hover, .wz-input.wz-input-password:hover {
    border-color: rgb(var(--primary-color)) !important;
}

.wz-label.wz-label__active.wz-label__inverted {
    color: rgb(var(--primary-color)) !important;
    background-color: rgba(var(--primary-color), 0.1);
}

.wz-commondropdown-list .wz-field-block-checkbox:hover {
    background-color: rgba(var(--primary-darker-color), 0.13) !important;
}

.wz-pellet {
    background-color: rgb(var(--primary-color)) !important;
}

.wz-label:hover {
    background-color: rgba(var(--primary-darker-color), 0.1);
    color: rgb(var(--primary-color));
}

.wz-label:hover i {
    color: rgb(var(--primary-color));
}

.wz-label.wz-label__active {
    border-color: rgb(var(--primary-color)) !important;
    color: rgb(var(--primary-color)) !important;
}

.wz-label.wz-label__active .wz-label-icon  i{
    color: rgb(var(--primary-color)) !important;
}

.wz-label.wz-label__active.wz-label__inverted .wz-label-icon i {
    color: rgb(var(--primary-color)) !important;
}

.wz-button-secondary {
    background-color: #d4d4d4 !important;
    border-color: #d4d4d4 !important;
    color: #000 !important;
}

.wz-input-switch input:checked + .wz-input-switch-slider {
    background-color: rgb(var(--primary-color)) !important;
    border-color: rgb(var(--primary-color)) !important;
}

.wz-input-switch input:focus + .wz-input-switch-slider {
    box-shadow: 0 2px 5px -2px rgb(var(--primary-color));
}

.wz-input.wz-input-textarea:not(.wz-input-textarea__errors):focus {
    box-shadow: 0 2px 5px -2px rgb(var(--primary-color));
    border-color: rgb(var(--primary-color));
}

.wz-tablecell.wz-tablecell__click:hover {
    color: rgb(var(--primary-color));
}

.wz-label .wz-label-icon__click i:hover {
    background-color: rgba(var(--primary-color), 0.13);
}

.wz-commondropdown:hover, .wz-commondropdown.wz-commondropdown__active {
    background-color: rgba(var(--primary-color), 0.13);
}

.wz-commondropdown:hover .wz-commondropdown__selectors,
.wz-commondropdown:hover .wz-commondropdown__selectors::before,
.wz-commondropdown:hover .wz-commondropdown__selectors::after,
.wz-commondropdown.wz-commondropdown__active .wz-commondropdown__selectors,
.wz-commondropdown.wz-commondropdown__active .wz-commondropdown__selectors::before,
.wz-commondropdown.wz-commondropdown__active .wz-commondropdown__selectors::after {
    background-color: rgb(var(--primary-color)) !important;
}
.events-picker-options {
	display: flex;
	justify-content: space-between;
}

.events-picker-options-name {
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 3px;
}

.events-picker-options-date {
	color: #C7C7C7;
	font-size: 11px;
}